import { createSlice } from "@reduxjs/toolkit";
import { User } from "firebase/auth";

import { FunctionPropertyNames } from "@/modules/common";

export type SerializedUser = Omit<User, FunctionPropertyNames<User>>;

type InitialState = {
  user: SerializedUser | null;
  isLoading: boolean;
  impersonateeEmail: string | null;
};

const initialState: InitialState = {
  user: null,
  isLoading: true,
  impersonateeEmail: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    hydrateUser: (state, { payload }) => {
      state.user = payload.user;
      state.isLoading = false;
    },
    setImpersonation: (state, { payload }) => {
      state.impersonateeEmail = payload.impersonateeEmail;
    },
  },
});

export const { hydrateUser, setIsLoading, setImpersonation } =
  authSlice.actions;
export const authReducer = authSlice.reducer;
