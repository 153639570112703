import { axios } from "@/modules/common/Axios";

export class AuthApi {
  public static async startImpersonation(email: string): Promise<any> {
    return axios
      .patch(`/api/v1/auth/impersonation/start/${email}`)
      .then(({ data }) => data);
  }

  public static async stopImpersonation(): Promise<any> {
    return axios
      .patch(`/api/v1/auth/impersonation/stop`)
      .then(({ data }) => data);
  }
}
