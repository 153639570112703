import {
  Accordion,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";
import { VscSettings } from "react-icons/vsc";

import { CTAButton, TextButton } from "@/modules/common";
import { useTags } from "@/modules/products/hooks";

type Props = {
  filters: JSX.Element;
  productsLength: number;
};

export function ProductFiltersDrawer({ filters, productsLength }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { resetFilter } = useTags();
  const btnRef = useRef<any>();

  return (
    <>
      <Button
        ref={btnRef}
        display={{ base: "inline-flex", lg: "none" }}
        leftIcon={<Icon as={VscSettings} />}
        data-cy="filtersButton"
        bg="bsBlack"
        color="white"
        fontWeight="regular"
        fontSize="sm"
        borderRadius={5}
        alignSelf="end"
        px={6}
        _hover={{
          bg: "bsBlack",
          opacity: 1,
          _disabled: {
            opacity: 1,
          },
        }}
        _active={{
          bg: "bsBlack",
        }}
        type="button"
        onClick={onOpen}
      >
        Filters
      </Button>

      <Drawer
        isOpen={isOpen}
        placement="left"
        finalFocusRef={btnRef}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton _hover={{ bg: "transparent" }} />
          <DrawerHeader color="bsBlack" fontSize="lg">
            <HStack justifyContent="space-between" pr={12}>
              <Text>Filters</Text>

              <TextButton
                text={"Reset filters"}
                variant={"primary"}
                onClick={() => resetFilter()}
              />
            </HStack>
          </DrawerHeader>

          <DrawerBody>
            <Accordion allowMultiple border="none" w="full" defaultIndex={[0]}>
              {filters}
            </Accordion>
          </DrawerBody>

          <DrawerFooter>
            <CTAButton
              buttonText={`Toon ${productsLength} producten`}
              width="full"
              action={onClose}
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
