import { Container } from "@chakra-ui/react";
import { DesktopHeader, MobileHeader } from "./components";

type Props = {
  isImpersonating: string | null;
};

export function Heading({ isImpersonating }: Props) {
  return (
    <Container
      maxW="full"
      backgroundColor="white"
      zIndex={2}
      px={0}
      h="content"
      position="sticky"
      boxShadow={{ base: "none", lg: "sm" }}
      top={isImpersonating ? 12 : 0}
      mb={isImpersonating ? 12 : 0}
    >
      <DesktopHeader />
      <MobileHeader />
    </Container>
  );
}
