import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";

import { updateOrderItemsThunk } from "@/modules/basket/data/thunks/UpdateOrderItemsThunk";
import { CTAButton, useAppDispatch } from "@/modules/common";
import { useNotifications } from "@/modules/notifications/hooks/UseNotifications";
import { ProductsApi } from "@/modules/products";

import { DownloadApi } from "../api/DownloadApi";
import { OrderHistoryDto } from "../api/OrderHistoryDto";

type Props = {
  order: OrderHistoryDto;
};

interface ProductReoorderDiff {
  productId: string;
  productName: string;
  exists: boolean;
  prevQuantity: number;
  curQuantity: number;
}

export const OrderLine = ({ order }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setIsLoading] = useState(false);
  const [downloadIsDisabled, setDownloadIsDisabled] = useState(false);
  const notification = useNotifications();
  const [productData, setProductData] = useState<ProductReoorderDiff[]>([]);

  const dispatch = useAppDispatch();

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
    }).format(price);
  };

  const onHandleRedoOrder = async () => {
    const productIds = order.orderLines.map(ol => ol.productId);

    const res = await ProductsApi.reoderInfo(productIds);
    const data = res.data.map(quantityInfo => quantityInfo.data);

    const resultData: ProductReoorderDiff[] = [];

    order.orderLines.forEach(ol => {
      let orderQuantity = ol.quantity;

      const foundProduct = data.find(d => d.id == ol.productId);

      if (!foundProduct) {
        resultData.push({
          productId: ol.productId,
          productName: ol.productName,
          curQuantity: ol.quantity,
          prevQuantity: ol.quantity,
          exists: false,
        });
        return;
      }

      if (orderQuantity < foundProduct.minQuantity) {
        orderQuantity = foundProduct.minQuantity;
      }

      if (orderQuantity > foundProduct.maxQuantity) {
        orderQuantity = foundProduct.maxQuantity;
      }

      const mod = orderQuantity % foundProduct.quantityStepSize;
      if (mod !== 0) {
        if (
          mod / foundProduct.quantityStepSize > 0.5 ||
          orderQuantity - mod <= 0
        ) {
          orderQuantity = orderQuantity - mod + foundProduct.quantityStepSize;
        } else {
          orderQuantity = orderQuantity - mod;
        }
      }

      resultData.push({
        exists: true,
        productId: ol.productId,
        productName: ol.productName,
        curQuantity: orderQuantity,
        prevQuantity: ol.quantity,
      });
    });

    setProductData(resultData);
  };

  const createAndDownloadBlobFile = (body: { data: number[] }) => {
    const blob = new Blob([new Uint8Array(body.data)], {
      type: "application/pdf",
    });
    const fileName = `Factuur-${order.orderNumber}.pdf`;
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const onDownload = () => {
    setDownloadIsDisabled(true);
    setIsLoading(true);
    DownloadApi.get(order.orderNumber)
      .then(data => {
        if (data.status === 404) {
          notification.error(
            "Foutmelding",
            "Deze factuur is momenteel niet beschikbaar. Probeer het later opnieuw."
          );
          return;
        }
        createAndDownloadBlobFile(data.data);
      })
      .finally(() => {
        setTimeout(() => setDownloadIsDisabled(false), 5000);
        setIsLoading(false);
      });
  };

  const handleAddToBasket = () => {
    dispatch(
      updateOrderItemsThunk(
        productData
          .filter(prodData => prodData.exists)
          .map(pd => ({
            productId: pd.productId,
            amount: pd.curQuantity,
          }))
      )
    );
  };

  useEffect(() => {
    if (isOpen) {
      onHandleRedoOrder();
    }
  }, [isOpen]);

  return (
    <>
      <AccordionItem
        key={order.createdAt}
        letterSpacing={1.2}
        border="1px solid"
        borderColor="gray.300"
        borderRadius={5}
        mb={3}
        fontSize="sm"
      >
        {/* TODO: Uncomment expanded, remove flex props and set hover to pointer */}
        <AccordionButton
          // _expanded={{
          //   bg: order.orderNumber ? "black" : "tomato",
          //   color: "white",
          // }}
          display="flex"
          flexDir="column"
          w="full"
          bg={order.orderNumber ? "none" : "tomato"}
          color={order.orderNumber ? "black" : "white"}
          borderTopRadius={5}
          _hover={{ cursor: "inherit" }}
        >
          <HStack w="full" justifyContent="space-between" mt={1}>
            <Box as="span" textAlign="left">
              Factuur {!order.invoiceNumber ? "-" : order.invoiceNumber}
              {" | "}
              Order {!order.orderNumber ? "-" : order.orderNumber}
            </Box>
            {/* TODO: Place date above AccordionIcon */}
            <Box as="span" textAlign="left">
              {format(parseISO(order.createdAt), "dd-MM-yyyy")}
            </Box>
          </HStack>
          {/* TODO: Remove buttons when invoice details are fixed and uncomment AccordionIcon */}
          <HStack w="full" justifyContent="space-between" gap={5}>
            {order.invoiceNumber && (
              <Button
                variant="unstyled"
                fontWeight="bold"
                fontSize="sm"
                borderRadius={5}
                _hover={{ bg: "none" }}
                isLoading={loading || downloadIsDisabled}
                disabled={downloadIsDisabled}
                onClick={onDownload}
              >
                Download factuur
              </Button>
            )}
            <Button
              variant="unstyled"
              fontWeight="bold"
              fontSize="sm"
              borderRadius={5}
              onClick={onOpen}
            >
              Herhaal bestelling
            </Button>
          </HStack>

          {/* <AccordionIcon /> */}
        </AccordionButton>
        {/* TODO: Remove display none from AccordionPanel */}
        <AccordionPanel
          display="none"
          pb={4}
          border="1px solid black"
          borderColor={order.orderNumber ? "black" : "tomato"}
          borderBottomRadius={5}
          p={{ base: 4, md: 16 }}
        >
          <Grid templateColumns="repeat(5, 1fr)" gap={2}>
            <GridItem colSpan={1} fontWeight="bold">
              Besteldatum
            </GridItem>
            <GridItem colSpan={4}>
              {format(parseISO(order.createdAt), "dd-MM-yyyy")}
            </GridItem>
            <GridItem colSpan={1} fontWeight="bold">
              Webordernummer
            </GridItem>
            <GridItem colSpan={4}>{order.orderNumber}</GridItem>
            <GridItem colSpan={1} fontWeight="bold">
              Factuurnummer
            </GridItem>
            <GridItem colSpan={4}>{order.invoiceNumber}</GridItem>
          </Grid>

          <Divider mt={12} mb={5} />

          <Grid templateColumns="repeat(14, 1fr)" gap={4} fontWeight="bold">
            <GridItem colSpan={1}></GridItem>
            <GridItem colSpan={7}>Product</GridItem>
            <GridItem colSpan={2}>Prijs</GridItem>
            <GridItem colSpan={2}>Aantal</GridItem>
            <GridItem colSpan={2}>Totaal</GridItem>
          </Grid>

          {order.orderLines.map(orderLine => (
            <Grid
              key={orderLine.productName}
              alignItems="center"
              templateColumns="repeat(14, 1fr)"
              gap={2}
              mt={2}
            >
              <GridItem colSpan={1}>
                <Image
                  h="50px"
                  alt={orderLine.productName}
                  src={orderLine.imageUrl}
                />
              </GridItem>
              <GridItem colSpan={7}>{orderLine.productName}</GridItem>
              <GridItem colSpan={2}>
                {formatPrice(orderLine.unitPrice)}
              </GridItem>
              <GridItem colSpan={2}>{orderLine.quantity}</GridItem>
              <GridItem>
                {formatPrice(orderLine.quantity * orderLine.unitPrice)}
              </GridItem>
            </Grid>
          ))}

          <Divider mt={5} mb={12} />

          <Grid templateColumns="repeat(14, 1fr)" gap={2} mt={12}>
            <GridItem colSpan={8}></GridItem>
            <GridItem colSpan={4}>Totaal excl. btw</GridItem>
            <GridItem>
              {formatPrice(
                order.priceWithoutVAT +
                  order.totalBalanceDiscount +
                  order.totalCouponDiscount
              )}
            </GridItem>
            {order.totalCouponDiscount !== 0 && (
              <>
                <GridItem colSpan={8}></GridItem>
                <GridItem colSpan={4}>Korting</GridItem>
                <GridItem colSpan={2}>
                  {formatPrice(order.totalCouponDiscount)}
                </GridItem>
              </>
            )}
            {order.totalBalanceDiscount !== 0 && (
              <>
                <GridItem colSpan={8}></GridItem>
                <GridItem colSpan={4}>Ingezet saldo</GridItem>
                <GridItem colSpan={2}>
                  {formatPrice(order.totalBalanceDiscount)}
                </GridItem>
              </>
            )}
            <GridItem colSpan={8}></GridItem>
            <GridItem colSpan={4}>Btw</GridItem>
            <GridItem colSpan={2}>{formatPrice(order.VATAmount)}</GridItem>
            <GridItem colSpan={8}></GridItem>
            <GridItem colSpan={4} fontWeight="bold">
              Totaal
            </GridItem>
            <GridItem colSpan={2} fontWeight="bold">
              {formatPrice(order.totalPrice)}
            </GridItem>
          </Grid>
          <HStack w="full" justifyContent="end" mt={16}>
            {order.invoiceNumber && (
              <Button
                variant="outline"
                fontWeight="regular"
                fontSize="sm"
                borderRadius={5}
                borderColor="black"
                _hover={{ bg: "none" }}
                isLoading={loading || downloadIsDisabled}
                disabled={downloadIsDisabled}
                onClick={onDownload}
              >
                Download factuur
              </Button>
            )}
            <CTAButton buttonText={"Herhaal bestelling"} action={onOpen} />
          </HStack>
        </AccordionPanel>
      </AccordionItem>

      <Modal isOpen={isOpen} size="3xl" onClose={onClose}>
        <ModalOverlay p={10} />
        <ModalContent letterSpacing={1.2}>
          <ModalHeader>Bestelling herhalen</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(12, 1fr)" gap={6} mb={2}>
              <GridItem colSpan={10} fontWeight="bold" fontSize="sm">
                Product
              </GridItem>
              <GridItem colSpan={2} fontWeight="bold" fontSize="sm">
                Aantal
              </GridItem>
            </Grid>
            {productData?.map(pd => {
              if (pd.productId === "410d3ef3-a07e-4dff-b2e6-df04747b47e5")
                return;

              return (
                <Grid
                  key={pd.productId}
                  templateColumns="repeat(12, 1fr)"
                  gap={6}
                  mb={2}
                  fontSize="sm"
                >
                  <GridItem
                    colSpan={10}
                    textDecor={pd.exists ? "none" : "line-through"}
                  >
                    {pd.productName}
                  </GridItem>
                  <GridItem colSpan={2}>
                    {pd.prevQuantity !== pd.curQuantity
                      ? `${pd.curQuantity}*`
                      : !pd.exists
                      ? "-"
                      : pd.curQuantity}
                  </GridItem>
                </Grid>
              );
            })}
            {productData.find(pd => pd.prevQuantity !== pd.curQuantity) && (
              <Text fontSize="sm" mt={5}>
                *Het minimum of maximum aantal te bestellen producten is
                gewijzigd waardoor de bestelaantallen kunnen afwijken van de
                vorige bestelling
              </Text>
            )}
          </ModalBody>

          <ModalFooter>
            <CTAButton
              buttonText="Toevoegen aan winkelwagen"
              action={handleAddToBasket}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
