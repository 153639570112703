import * as Auth from "./auth";
import * as Basket from "./basket";
import * as Payment from "./checkout";
import * as Common from "./common";
import * as Maintenance from "./maintenance";
import { ModuleRouter } from "./common/types";
import * as Customer from "./customers";
import * as History from "./history";
import * as Order from "./orders";
import * as Products from "./products";
import * as Pricelists from "./pricelists";

export const routerConfig: ModuleRouter = [
  ...Products.productsPageRouterConfig,
  ...Basket.routerConfig,
  ...Auth.authPageRouterConfig,
  ...Customer.customerPageConfiguration,
  ...Payment.paymentPageRouterConfig,
  ...History.historyPageConfiguration,
  ...Order.orderResponseRouterConfig,
  ...Common.notFoundPageRouterConfig,
  ...Maintenance.maintenancePageRouterConfig,
  ...Pricelists.pricelistsPageConfiguration,
];
