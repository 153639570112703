import {
  Container,
  Flex,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { Link as ReactLink, Navigate, useLocation } from "react-router-dom";
import { ReturnToLastPageButton, useAppSelector } from "../common";
import { selectIsAuthenticated } from "@/modules/auth/data/selectIsAuthenticated";
import { db } from "../auth/firebase";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";

type PricelistData = {
  moroccanoil: {
    body: string;
    color: string;
    hair: string;
  };
  mydentity: string;
  oligo: string;
};

export function PricelistsPage() {
  const [priceLists, setPriceLists] = useState<PricelistData>({
    moroccanoil: {
      body: "",
      color: "",
      hair: "",
    },
    mydentity: "",
    oligo: "",
  });

  useEffect(() => {
    const fetchPricelistData = async () => {
      try {
        const configDoc = doc(db, "config", "priceLists");
        const docSnap = await getDoc(configDoc);
        const data: PricelistData = docSnap.data() as PricelistData;
        setPriceLists(data);
      } catch (err) {}
    };

    fetchPricelistData();
  }, []);

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const { pathname } = useLocation();

  if (!isAuthenticated) {
    return (
      <Navigate
        replace
        to={{ pathname: "/inloggen", search: `redirect=${pathname}` }}
      />
    );
  }

  return (
    <Container maxW="1248px" mx="auto" h="full" pb={8}>
      <ReturnToLastPageButton />

      <SimpleGrid columns={{ base: 1, lg: 2 }} spacingX={16} pb={6}>
        <Flex flexDir="column" w="half">
          <Flex justifyContent="space-between" w="full" flexDir="column">
            <Heading
              as="h1"
              size={"2xl"}
              fontWeight="bold"
              lineHeight={1.2}
              mb={8}
            >
              <Text lineHeight={1.2}>Prijslijsten</Text>
            </Heading>
          </Flex>
          {priceLists?.moroccanoil && (
            <>
              <Heading as="h2" size={"l"} fontWeight="bold" lineHeight={1.2}>
                <Text lineHeight={1.2}>Moroccanoil</Text>
              </Heading>
              {priceLists.moroccanoil.hair && (
                <Link
                  href={priceLists.moroccanoil.hair}
                  target="_blank"
                  letterSpacing={1}
                  fontSize="sm"
                >
                  Download prijslijst Moroccanoil Hair
                </Link>
              )}
              {priceLists.moroccanoil.color && (
                <Link
                  href={priceLists.moroccanoil.color}
                  target="_blank"
                  letterSpacing={1}
                  fontSize="sm"
                >
                  Download prijslijst Moroccanoil Color
                </Link>
              )}
              {priceLists.moroccanoil.body && (
                <Link
                  href={priceLists.moroccanoil.body}
                  target="_blank"
                  mb={5}
                  letterSpacing={1}
                  fontSize="sm"
                >
                  Download prijslijst Moroccanoil Body
                </Link>
              )}
            </>
          )}
          {priceLists?.mydentity && (
            <>
              <Heading as="h2" size={"l"} fontWeight="bold" lineHeight={1.2}>
                <Text lineHeight={1.2}>Mydentity</Text>
              </Heading>
              <Link
                href={priceLists.mydentity}
                target="_blank"
                mb={5}
                letterSpacing={1}
                fontSize="sm"
              >
                Download prijslijst Mydentity
              </Link>
            </>
          )}
          {priceLists?.oligo && (
            <>
              <Heading as="h2" size={"l"} fontWeight="bold" lineHeight={1.2}>
                <Text lineHeight={1.2}>Oligo Professionnel</Text>
              </Heading>
              <Link
                href={priceLists.oligo}
                target="_blank"
                mb={5}
                letterSpacing={1}
                fontSize="sm"
              >
                Download prijslijst Oligo Professionnel
              </Link>
            </>
          )}
        </Flex>
      </SimpleGrid>
    </Container>
  );
}
