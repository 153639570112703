import {
  Box,
  Checkbox,
  Flex,
  Icon,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CiDeliveryTruck } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

import { AddBalance, Coupon } from "@/modules/basket";
import {
  selectDeliveryCosts,
  selectRawPrice,
  selectSaturdayDelivery,
  selectTotalPrice,
  selectVATAmount,
} from "@/modules/basket/data/selectors";
import { selectBalanceDiscount } from "@/modules/basket/data/selectors/SelectBalanceDiscount";
import OverviewBasketProductsList from "@/modules/checkout/components/OverviewBasketProductsList";
import { CTAButton, Title, useAppSelector, useMoney } from "@/modules/common";
import { LabeledPrice } from "@/modules/common/components/LabeledPrice";

import { selectActiveDiscount } from "../data/selectors/SelectActiveDiscount";
import { selectCouponsDiscount } from "../data/selectors/SelectCouponsDiscount";
import { useState } from "react";
import { useErrorModes } from "@/modules/auth/hooks/useErrorModes";

type Props = {
  isConfirmationOverview?: boolean;
  createOrder?: () => void;
  isLoading?: boolean;
  paymentMethod?: string;
  showMissingPaymentMethodWarning?: () => void;
};

export function BasketOverview({
  isConfirmationOverview = false,
  createOrder,
  isLoading = false,
  paymentMethod,
  showMissingPaymentMethodWarning,
}: Props) {
  const navigate = useNavigate();

  const untilFreeDelivery = 150;

  const uiMode = useErrorModes();
  const rawPrice = useAppSelector(selectRawPrice);
  const totalPrice = useAppSelector(selectTotalPrice);
  const vatAmount = useAppSelector(selectVATAmount);
  const balanceDiscount = useAppSelector(selectBalanceDiscount);
  const couponsDiscount = useAppSelector(selectCouponsDiscount);
  const saturdayDelivery = useAppSelector(selectSaturdayDelivery);
  const activeDiscount = useAppSelector(selectActiveDiscount);
  const deliveryCosts = useAppSelector(selectDeliveryCosts);
  const priceUntilFreeDelivery = useMoney(untilFreeDelivery - rawPrice);

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [showTermsWarning, setShowTermsWarning] = useState(false);
  const warningColor = "#E53E3E";

  const handleDisabledClick = () => {
    if (!paymentMethod && showMissingPaymentMethodWarning) {
      showMissingPaymentMethodWarning();
    }

    if (!hasAcceptedTerms) {
      setShowTermsWarning(true);
    }
  };

  return (
    <Flex
      w={{ base: "100%", md: "50%", lg: "40%" }}
      flexDir="column"
      bg="bsGray.100"
      p={{ base: 4, md: 6 }}
      gap={4}
      border="1px solid"
      borderColor="gray.200"
      h="fit-content"
      borderRadius="5"
    >
      <Title text="Overzicht" fontSize="md" />
      <Box height="1px" w="full" bg="gray.300" />
      <Flex mb={3}>
        <Coupon />
      </Flex>
      <Flex>
        <AddBalance />
      </Flex>
      {rawPrice < untilFreeDelivery && (
        <Flex gap={4} alignItems="start">
          <Icon as={CiDeliveryTruck} w={8} h={8} />
          <VStack spacing={1} w="full">
            <Text w="full" fontSize="xs">
              Nog {priceUntilFreeDelivery} tot GRATIS verzending
            </Text>
            <Progress
              w="full"
              h="2"
              borderRadius="full"
              value={rawPrice}
              max={untilFreeDelivery}
              bg="white"
            />
          </VStack>
        </Flex>
      )}

      <Box mt="auto">
        {isConfirmationOverview && <OverviewBasketProductsList />}
        <Flex justifyContent="space-between" flexDir="column" gap={4} mt={8}>
          <LabeledPrice flexRow label="Totaal" price={rawPrice} />
          {!!couponsDiscount && (
            <LabeledPrice
              flexRow
              isDiscount
              label={`Korting (${activeDiscount?.code})`}
              price={couponsDiscount}
            />
          )}
          {!!balanceDiscount && (
            <LabeledPrice
              flexRow
              isDiscount
              label="Gebruikt saldo"
              price={balanceDiscount}
            />
          )}
          {!!deliveryCosts && (
            <LabeledPrice
              flexRow
              label="Verzend- en handlingskosten"
              price={Number(deliveryCosts.price)}
            />
          )}
          {!!saturdayDelivery && (
            <LabeledPrice
              flexRow
              label="Weekendlevering"
              price={Number(saturdayDelivery.price)}
            />
          )}
          <LabeledPrice flexRow label="btw" price={vatAmount} size="sm" />

          <LabeledPrice
            flexRow
            boldLabel
            boldPrice
            label="Te betalen"
            price={totalPrice}
            size="sm"
          />
        </Flex>
        {!isConfirmationOverview && (
          <Flex mt={10} flexDir={{ base: "column", md: "row", lg: "column" }}>
            <CTAButton
              data-cy="goToShipmentButton"
              buttonText={"Bestellen"}
              disabled={uiMode.technicalDifficulties}
              action={() => navigate("/checkout")}
            />
            {uiMode.technicalDifficulties && (
              <Text mt="8">
                Bestellen is op dit moment niet mogelijk. Excuses voor het
                ongemak.
              </Text>
            )}
          </Flex>
        )}
        {isConfirmationOverview && (
          <>
            <Checkbox
              isChecked={hasAcceptedTerms}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setHasAcceptedTerms(e.target.checked);
              }}
              mt={6}
            >
              <span
                style={
                  showTermsWarning && !hasAcceptedTerms
                    ? {
                        color: warningColor,
                      }
                    : {}
                }
              >
                Ik ga akkoord met de{" "}
                <a
                  style={{ textDecoration: "underline" }}
                  href="https://www.beautydistrict.nl/wp-content/uploads/Beautydistrict_Algemene_Voorwaarden.pdf"
                  target="_blank"
                >
                  algemene voorwaarden
                </a>
              </span>
            </Checkbox>
            <CTAButton
              width={"100%"}
              order={{ md: "2", sm: "1" }}
              marginTop={6}
              alignSelf="end"
              type="submit"
              disabled={isLoading || !paymentMethod || !hasAcceptedTerms}
              isLoading={isLoading}
              buttonText="Bestelling betalen"
              action={createOrder}
              disabledAction={handleDisabledClick}
            />
          </>
        )}
      </Box>
    </Flex>
  );
}
