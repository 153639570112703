import { useStopImpersonation } from "@/modules/auth/hooks/useStopImpersonation";
import { Button, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";

export type ImpersonationBannerProps = {
  impersonateeEmail: string;
};

export function ImpersonationBanner({
  impersonateeEmail,
}: ImpersonationBannerProps) {
  const stopImpersonation = useStopImpersonation();
  const [isLoading, setIsLoading] = useState(false);

  function onStop() {
    setIsLoading(true);
    stopImpersonation();
  }

  return (
    <Flex
      maxW="full"
      w="full"
      position="fixed"
      top="0"
      background="red.500"
      p="2"
      justifyContent="center"
      zIndex="10"
    >
      <Flex maxW="1248px" alignItems="center" gap={{ base: 2, sm: 4 }}>
        <Text color="white" fontSize={{ base: "xs", sm: "sm" }}>
          <>
            <b>Let op: </b>
            Je gebruikt de webshop op dit moment als {impersonateeEmail}
          </>
        </Text>
        <Button
          variant="outline"
          colorScheme="red"
          color="white"
          size={{ base: "xs", sm: "sm", md: "sm" }}
          w={{ base: "250px", sm: "220px", md: "150px", lg: "150px" }}
          p={2}
          h="auto"
          isLoading={isLoading}
          onClick={onStop}
          _hover={{
            background: "white",
            color: "red.500",
          }}
        >
          Beëindigen
        </Button>
      </Flex>
    </Flex>
  );
}
