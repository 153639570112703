import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getErrorMode } from "../firebase";

export type UIErrorMode = {
  maintenance: boolean;
  technicalDifficulties: boolean;
};

export function useErrorModes(email?: string | null) {
  const initialMode: UIErrorMode = {
    maintenance: false,
    technicalDifficulties: false,
  };

  const navigate = useNavigate();
  const location = useLocation();

  const [mode, setMode] = useState<UIErrorMode>(initialMode);

  useEffect(() => {
    async function fetchModes() {
      const modes = await getErrorMode(email);
      if (modes.maintenance) {
        navigate("/maintenance");
      } else {
        if (location.pathname === "/maintenance") {
          navigate("/");
        }
      }
      setMode(modes);
    }

    fetchModes();
  }, []);

  return mode;
}
