import { FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { UseFormRegisterReturn } from "react-hook-form";

type Props = {
  label: string;
  register: UseFormRegisterReturn;
  isDisabled?: boolean;
  isInvalid?: boolean;
  errorMessage?: string;
  showLabel?: boolean;
  hasAutoFocus?: boolean;
  type?: string;
};

export function CustomInputField({
  label,
  register,
  isInvalid,
  errorMessage,
  isDisabled = false,
  showLabel = true,
  hasAutoFocus = false,
  type = "text",
}: Props) {
  return (
    <FormControl w="full" isInvalid={isInvalid} borderRadius="5">
      {showLabel && (
        <FormLabel fontSize="sm" ml={2} mb={1}>
          {label}
        </FormLabel>
      )}
      <Input
        size="md"
        fontSize="md"
        disabled={isDisabled}
        placeholder={label}
        type={type}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={hasAutoFocus}
        {...register}
        w="full"
        p={4}
        borderRadius={5}
        borderColor="#b2b2b2"
        _disabled={{
          bg: "#f4f4f4",
          "&:hover": { borderColor: "inherit" },
        }}
        _focusVisible={{
          outlineOffset: "-1px",
          outline: "2px solid",
          outlineColor: "bsBlack",
        }}
      />
      {errorMessage && (
        <Text color="#E53E3E" fontSize="xs" mt={1} ml={2}>
          {errorMessage}
        </Text>
      )}
    </FormControl>
  );
}
