import { Button } from "@chakra-ui/react";
import { ReactElement } from "react";

type Props = {
  leftIcon?: ReactElement;
  isLoading?: boolean;
  /** Gets called when the button is clicked while it is enabled */
  action?: () => void;
  /** Gets called when the button is clicked while it is disabled */
  disabledAction?: () => void;
  type?: "button" | "submit" | "reset" | undefined;
  buttonText: string;
  order?: { md: string; sm: string };
  alignSelf?: string;
  width?: { md: string; sm: string } | string;
  marginBottom?: number;
  marginTop?: number;
  disabled?: boolean;
  dataTestid?: string;
};

export function CTAButton({
  isLoading,
  action,
  disabledAction,
  buttonText,
  leftIcon,
  type,
  order,
  alignSelf,
  width,
  marginBottom,
  marginTop,
  disabled,
  dataTestid,
}: Props) {
  const disabledStyle = {
    opacity: 0.4,
    cursor: "not-allowed",
  };

  function handleClick() {
    if (disabled && disabledAction) {
      disabledAction();
    }

    if (!disabled && action) {
      action();
    }
  }

  return (
    <Button
      data-testid={dataTestid}
      leftIcon={leftIcon}
      {...(order && { order: order })}
      {...(alignSelf && { alignSelf: alignSelf })}
      {...(marginBottom && { mb: marginBottom })}
      {...(marginTop && { mt: marginTop })}
      bg="bsBlack"
      color="bsWhite"
      fontWeight="regular"
      fontSize="sm"
      borderRadius={5}
      px={5}
      w={width ? width : "content"}
      isLoading={isLoading}
      style={disabled ? disabledStyle : {}}
      _hover={{
        bg: "bsBlack",
        _disabled: {
          opacity: 0.4,
        },
      }}
      _active={{
        bg: "bsBlack",
      }}
      type={type ? type : "button"}
      onClick={handleClick}
    >
      {buttonText}
    </Button>
  );
}
