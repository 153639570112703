import { Flex, Heading as ChakraHeading, Link, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { useNotifications } from "@/modules/notifications/hooks/UseNotifications";

import { CTAButton, CustomInputField } from "../common";
import { Heading } from "../layout";
import { Banner } from "../layout/components/Banner/Banner";
import { auth } from "./firebase";

const schema = yup.object({
  email: yup
    .string()
    .email("Vul een geldig e-mailadres in om het wachtwoord te resetten")
    .required("Vul een e-mailadres in"),
});

export function ResetPasswordPage() {
  const navigate = useNavigate();
  const notifications = useNotifications();
  const [loading, setLoading] = useState(false);

  const searchParams = new URLSearchParams(document.location.search);
  const prefill = searchParams.get("email");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: prefill || "",
    },
  });

  const navigateToLogin = (hasError?: boolean) => {
    if (hasError) {
      notifications.error(
        "Wachtwoord resetten mislukt",
        "Het resetten van het wachtwoord is mislukt. Probeer het later nog eens"
      );
    } else {
      notifications.info(
        "Wachtwoord vergeten",
        "Je ontvangt een reset mail als dit e-mailadres bij ons bekend is"
      );
      navigate("/inloggen");
    }
  };

  const onSubmit = handleSubmit(async data => {
    setLoading(true);

    try {
      await sendPasswordResetEmail(auth, data.email);
      navigateToLogin();
    } catch (e: any) {
      if (e.code === "auth/user-not-found") {
        navigateToLogin(false);
      } else {
        navigateToLogin(true);
      }
    } finally {
      setLoading(false);
    }
  });

  return (
    <Flex
      h="full"
      w="full"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      maxW="1248px"
      mx="auto"
    >
      <Flex
        flexDir="column"
        w="half"
        justifyContent="center"
        mx={{ base: 5, md: 20 }}
        py={6}
        gap={2}
      >
        <ChakraHeading
          as="h1"
          size={{ sm: "lg", md: "2xl" }}
          fontWeight="bold"
          lineHeight={1.2}
          mb={{ base: 4, md: 8 }}
        >
          <Text lineHeight={1.2}>
            Wachtwoord <i>RESETTEN</i>
          </Text>
        </ChakraHeading>

        <Text fontSize="sm">
          Vul je e-mailadres in om je wachtwoord te resetten
        </Text>
        <form onSubmit={onSubmit}>
          <Flex
            justifyContent="space-between"
            w="full"
            flexDir="column"
            mt={5}
            gap={4}
          >
            <CustomInputField
              showLabel={false}
              label="E-mailadres"
              isInvalid={Boolean(errors.email)}
              errorMessage={errors.email?.message}
              register={register("email")}
            />
            <CTAButton
              type="submit"
              width="full"
              isLoading={loading}
              buttonText="Wachtwoord resetten"
            />

            <Link
              as={ReactLink}
              to={"/"}
              mt={5}
              data-cy="resetPasswordLink"
              alignSelf="center"
              fontSize="sm"
              _hover={{ textDecor: "underline" }}
            >
              Terug naar inloggen
            </Link>
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
}
