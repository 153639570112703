import { createAsyncThunk } from "@reduxjs/toolkit";

import { BasketApiImpl } from "../../api";

export const updateActiveDiscountThunk = createAsyncThunk(
  "basket/update-discount",
  async (code: string | null) => {
    return BasketApiImpl.getInstance().updateActiveDiscount({
      code,
    });
  }
);
