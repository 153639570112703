import { Button, HStack, Input, Text, VStack } from "@chakra-ui/react";
import debounce from "lodash/debounce";
import { useCallback, useState } from "react";

import { BasketProduct, OrderItem } from "@/modules/basket";
import { selectIsProductInBasket } from "@/modules/basket/data/selectors/SelectIsProductInBasket";
import { selectProductAmount } from "@/modules/basket/data/selectors/SelectProductAmount";
import { ProductDto } from "@/modules/products";

import { useAppSelector } from "../Store";
import {
  ProductQuantityHandler,
  QuantityHandlerType,
} from "../utils/ProductQuantityHandler";

type Props = (BasketProduct | ProductDto) & {
  onAmountChange?(amount: number): void;
  updateBasket(item: OrderItem): void;
};

export function ChangeProductAmount(props: Props) {
  const {
    onAmountChange,
    updateBasket,
    quantityStepSize,
    minQuantity,
    maxQuantity,
    ...product
  } = props;
  const { id } = product;

  const productAmount = useAppSelector(selectProductAmount(id));

  const [value, setValue] = useState<number>(productAmount);
  const inBasket = useAppSelector(selectIsProductInBasket(id));

  function getQuantityMessage() {
    if (minQuantity > 0 && maxQuantity !== Number.MAX_SAFE_INTEGER) {
      return `(min. ${minQuantity} - max. ${maxQuantity})`;
    }
  }

  function handleOnChange(type: QuantityHandlerType, manualValue?: number) {
    const newValue = new ProductQuantityHandler().handle({
      type: type,
      value: value,
      manualValue: manualValue,
      minQuantity: minQuantity,
      maxQuantity: maxQuantity,
      stepSize: quantityStepSize,
    });

    setValue(newValue);

    const orderItem: OrderItem = {
      productId: product.id,
      amount: newValue,
    };

    debouncedUpdateBasket(orderItem);
    onAmountChange?.(newValue);
  }

  const handleFocus = (event: any) => {
    event.target.select();
  };

  const debouncedUpdateBasket = useCallback(debounce(updateBasket, 300), [
    inBasket,
  ]);

  return (
    <VStack justifyContent="start" alignItems="end">
      <Text color="gray.800" fontSize="2xs" textAlign="end">
        {getQuantityMessage()}
      </Text>
      <HStack
        w="fit-content"
        border="1px solid"
        borderColor="bsGray.200"
        borderRadius={5}
        px={1}
      >
        <Button
          bg="bsWhite"
          color={productAmount < 1 ? "bsGray.200" : "bsBlack"}
          position="relative"
          size="sm"
          _hover={{ bg: "bsWhite" }}
          _active={{ bg: "bsWhite" }}
          onClick={() => handleOnChange("decr")}
        >
          -
        </Button>
        <Input
          border="2px solid bsWhite"
          variant="unstyled"
          textAlign="center"
          data-cy="productAmount"
          value={value}
          w="32px"
          size="xs"
          min={0}
          step="any"
          type="number"
          onChange={e => setValue(+e.target.value)}
          onBlur={e => handleOnChange("manual", +e.currentTarget.value)}
          onFocus={handleFocus}
        />
        <Button
          bg="bsWhite"
          color={productAmount >= maxQuantity ? "bsGray.200" : "bsBlack"}
          position="relative"
          _hover={{ bg: "bsWhite" }}
          _active={{ bg: "bsWhite" }}
          size="sm"
          onClick={() => handleOnChange("incr")}
        >
          +
        </Button>
      </HStack>
    </VStack>
  );
}
