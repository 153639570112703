import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Link,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import { signOut } from "firebase/auth";
import { FiUser } from "react-icons/fi";
import { Link as RouterLink } from "react-router-dom";

import { selectUser } from "@/modules/auth/data/selectUser";
import { auth } from "@/modules/auth/firebase";
import { useAppSelector } from "@/modules/common";
import { selectCustomer } from "@/modules/customers";
import { selectImpersonation } from "@/modules/auth/data/selectImpersonation";
import { useRef, useState } from "react";
import { useStartImpersonation } from "@/modules/auth/hooks/useStartImpersonation";

export function UserMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const impersonationInputRef = useRef(null);
  const [impersonationTarget, setImpersonationTarget] = useState<string>("");
  const startImpersonation = useStartImpersonation();

  const user = useAppSelector(selectUser);
  const customer = useAppSelector(selectCustomer);
  const impersonateeEmail = useAppSelector(selectImpersonation);

  function onLogout(): void {
    signOut(auth);
  }

  function onStartImpersonation(): void {
    setIsLoading(true);
    startImpersonation(impersonationTarget);
  }

  function onInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.key === "Enter") {
      onStartImpersonation();
    }
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={impersonationInputRef}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Inloggen als klant</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Om klanten te ondersteunen is het mogelijk om als een klant in te
            loggen en gebruik te maken van de webshop. Hierna kun je namens de
            klant:
            <UnorderedList mt="4" mb="4">
              <ListItem>Een winkelmandje klaarzetten</ListItem>
              <ListItem>Een order plaatsen</ListItem>
              <ListItem>Contactgegevens of wachtwoord wijzigen</ListItem>
            </UnorderedList>
            Vul in onderstaand veld het e-mailadres in van de klant voor wie je
            wenst in te loggen.
            <FormControl mt="4">
              <FormLabel>E-mailadres</FormLabel>
              <Input
                type="email"
                placeholder="john@example.com"
                value={impersonationTarget}
                onChange={e => setImpersonationTarget(e.target.value)}
                ref={impersonationInputRef}
                onKeyDown={onInputKeyDown}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onStartImpersonation} isLoading={isLoading}>
              Inloggen
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Menu>
        <MenuButton
          data-cy="userMenuButton"
          as={IconButton}
          bg="none"
          _hover={{ bg: "none" }}
          disabled={!user}
        >
          <Icon
            color="#5e5753"
            as={FiUser}
            w={{ base: 5, md: 7 }}
            h={{ base: 5, md: 7 }}
            mt={1}
          />
        </MenuButton>
        <MenuList
          bg="bsWhite"
          border="1px solid"
          borderColor="bsGray.200"
          borderRadius={5}
          p={0}
          data-testid="menuDropdown"
          zIndex={3}
        >
          <MenuItem
            borderTopRadius="5"
            fontSize="xs"
            data-cy="logoutButton"
            fontWeight="bold"
            p={5}
            _hover={{
              cursor: "default",
              bg: "transparent",
            }}
            _focus={{
              bg: "transparent",
            }}
          >
            <Text>Welkom {customer?.data?.email}</Text>
          </MenuItem>
          <Divider borderColor="bsGray.200" />
          <Link as={RouterLink} to="/account" _hover={{ textDecor: "none" }}>
            <MenuItem
              fontSize="xs"
              h={14}
              px={5}
              _hover={{
                bg: "bsAccent",
                color: "bsWhite",
              }}
            >
              Account
            </MenuItem>
          </Link>
          <Link
            as={RouterLink}
            to="/bestellingen"
            _hover={{ textDecor: "none" }}
          >
            <MenuItem
              fontSize="xs"
              h={14}
              px={5}
              _hover={{
                bg: "bsAccent",
                color: "bsWhite",
              }}
            >
              Bestellingen
            </MenuItem>
          </Link>
          {customer?.data?.canImpersonate && !impersonateeEmail && (
            <MenuItem
              fontSize="xs"
              h={14}
              px={5}
              _hover={{
                bg: "bsAccent",
                color: "bsWhite",
              }}
              onClick={onOpen}
            >
              Inloggen als klant
            </MenuItem>
          )}

          <Link
            as={RouterLink}
            to="/prijslijsten"
            _hover={{ textDecor: "none" }}
          >
            <MenuItem
              fontSize="xs"
              h={14}
              px={5}
              _hover={{
                bg: "bsAccent",
                color: "bsWhite",
              }}
            >
              Prijslijsten
            </MenuItem>
          </Link>

          <MenuItem
            borderBottomRadius="5"
            fontSize="xs"
            data-cy="logoutButton"
            p={5}
            _hover={{
              bg: "bsAccent",
              color: "bsWhite",
            }}
            onClick={onLogout}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
