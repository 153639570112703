import { useNotifications } from "@/modules/notifications/hooks/UseNotifications";
import { useCallback } from "react";
import { AuthApi } from "../api/AuthApi";
import { auth } from "../firebase";

export function useStopImpersonation() {
  const notifications = useNotifications();

  const stopImpersonation = useCallback(async () => {
    console.warn("Stopping impersonation");
    AuthApi.stopImpersonation();
    setTimeout(async () => {
      await auth.currentUser?.getIdToken(true);

      const tokenResult = await auth.currentUser?.getIdTokenResult();
      console.warn({ tokenResult });

      if (!tokenResult || tokenResult?.claims.impersonating) {
        notifications.error(
          "Er ging iets mis",
          "Het is niet gelukt om de sessie te beëindigen. Ververs de pagina en probeer het opnieuw."
        );
        if (!tokenResult) {
          console.error("Failed to get tokenResult");
          return;
        }

        console.error("Failed to delete user claims");
        return;
      }

      window.location.reload();
    }, 1000);
  }, []);

  return stopImpersonation;
}
