import { Container, useMediaQuery } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../common";
import {
  loadMoreProducts,
  ProductsList,
  selectProducts,
  selectProductStatus,
  useProducts,
} from ".";

export type OnFilterFn = (category: string, value: string) => void;
export type OnSearchFn = (value: string) => void;
export type IsActiveFn = (category: string, value: string) => boolean;

export type Tag = {
  category: string;
  value: string;
  isDefault: boolean;
};

export const FETCH_LIMIT = 36;

export function ProductsPage() {
  const { refetchProducts } = useProducts();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const products = useAppSelector(selectProducts);
  const status = useAppSelector(selectProductStatus);

  const onLoadMore = () => {
    dispatch(
      loadMoreProducts({
        page: products.currentPage + 1,
        limit: FETCH_LIMIT,
      })
    );
  };

  const [isLargerThan960] = useMediaQuery("(min-width: 1200px)");

  useEffect(() => {
    if (isLargerThan960 && !isOpen) {
      setIsOpen(true);
    }
    if (!isLargerThan960 && isOpen) {
      setIsOpen(false);
    }
  }, [isLargerThan960]);

  useEffect(() => {
    if (products.currentPage > 1 || products.data.length > 0) {
      return;
    }

    refetchProducts();
  }, []);

  const isLoading = status === "loading";
  const isIdle = status === "idle";

  return (
    <Container maxW="1248px" h="full" pb={8} px={{ base: 2, md: 4 }}>
      <ProductsList
        products={products.data}
        hasMore={products.hasMore}
        isIdle={isIdle}
        isLoading={isLoading}
        onLoadMore={onLoadMore}
      />
    </Container>
  );
}
