import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import {
  CustomInputField,
  TextButton,
  useAppDispatch,
  useAppSelector,
} from "@/modules/common";
import { CTAButton } from "@/modules/common/components/CTAButton";
import { isHttpProblemDetail } from "@/modules/notifications/data/IsHttpProblemDetail";

import { selectActiveDiscount } from "../data/selectors/SelectActiveDiscount";
import { updateActiveDiscountThunk } from "../data/thunks/UpdateActiveDiscountThunk";

export function Coupon() {
  const dispatch = useAppDispatch();
  const activeDiscount = useAppSelector(selectActiveDiscount);
  const [loading, setLoading] = useState(false);

  const schema = yup.object({
    coupon: yup
      .string()
      .required("Kortingscode dient minimaal 1 karakter te bevatten")
      .min(1),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      coupon: null,
    },
  });

  const handleCoupon = handleSubmit(async data => {
    if (!data.coupon) return;

    setLoading(true);
    const res = await dispatch(updateActiveDiscountThunk(data.coupon));
    setLoading(false);

    if (isHttpProblemDetail(res.payload)) {
      return;
    }

    reset();
  });

  async function onDelete() {
    await dispatch(updateActiveDiscountThunk(null));
  }

  return (
    <>
      <Accordion
        allowToggle={!activeDiscount}
        index={!activeDiscount ? undefined : 0}
        w="full"
      >
        <AccordionItem border="none">
          <AccordionButton _hover={{ bg: "transparent" }} p={0}>
            <Box
              as="span"
              flex="1"
              textAlign="left"
              fontWeight="bold"
              fontSize="sm"
            >
              {!activeDiscount
                ? "Heb jij een kortingscode?"
                : "Jouw kortingscode"}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel p={0}>
            {!activeDiscount ? (
              <>
                <Text fontSize="xs" mt={4} ml={1}>
                  Slechts één kortingscode per bestelling mogelijk
                </Text>

                <form onSubmit={handleCoupon}>
                  <HStack justifyContent="end" alignItems="start" mt={2} p={1}>
                    <CustomInputField
                      hasAutoFocus
                      label="Kortingscode"
                      register={register("coupon")}
                      isInvalid={Boolean(errors.coupon)}
                      errorMessage={errors.coupon?.message}
                      showLabel={false}
                    />
                    <CTAButton
                      data-cy="addCouponCode"
                      buttonText="Toevoegen"
                      isLoading={loading}
                      type="submit"
                      data-name="addCouponCode"
                    />
                  </HStack>
                </form>
              </>
            ) : (
              <VStack alignItems="flex-start" mt={4} p={1} spacing={1}>
                <HStack justifyContent="space-between" w="full">
                  <Text>{activeDiscount.code}</Text>
                  <TextButton
                    text="Verwijderen"
                    variant="primary"
                    onClick={() => onDelete()}
                  />
                </HStack>
                <Text fontSize="sm" w="full" fontStyle="italic">
                  {activeDiscount.description}
                </Text>
              </VStack>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}
