import {
  Accordion,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { ReturnToLastPageButton, Spinner } from "../common";
import { isHttpProblemDetail } from "../notifications/data/IsHttpProblemDetail";
import { OrdersApi } from "../orders/api";
import { OrderHistoryDto } from "./api/OrderHistoryDto";
import { OrderLine } from "./components";

export const OrderHistoryPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState<OrderHistoryDto[]>([]);

  useEffect(() => {
    setIsLoading(true);
    OrdersApi.getOrderHistory()
      .then(data => {
        if (isHttpProblemDetail(data)) return;
        setOrders(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <Spinner />;

  return (
    <Container maxW="1248px" mx="auto" pb={8}>
      <ReturnToLastPageButton />

      <Flex pb={6}>
        <Flex flexDir="column" w="full">
          <Flex justifyContent="space-between" w="full" flexDir="column">
            <Heading as="h1" size={"2xl"} fontWeight="bold" mb={4}>
              <Text lineHeight={1.2}>
                Jouw <i>Bestellingen</i>
              </Text>
            </Heading>
            <Grid templateColumns="repeat(1, 1fr)" gap={6}>
              <GridItem colSpan={1}>
                {orders.length > 0 && (
                  <Accordion allowMultiple allowToggle defaultIndex={0}>
                    {orders.map(order => (
                      <OrderLine key={order.createdAt} order={order} />
                    ))}
                  </Accordion>
                )}
                {orders.length < 1 && (
                  <Text
                    color="gray.500"
                    fontSize="sm"
                    data-cy="emptyListMessage"
                    mt={5}
                    textAlign={{ base: "left", lg: "center" }}
                  >
                    Er zijn geen bestellingen gevonden
                  </Text>
                )}
              </GridItem>
            </Grid>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};
