import { createSlice } from "@reduxjs/toolkit";

import { Status } from "@/modules/common";
import { getPaymentProviderOptionsThunk } from "@/modules/customers/api/GetPaymentProviderOptionsThunk";

import {
  CustomerDto,
  CustomerDtoAddressDto,
  errorMapper,
  NewCustomerDto,
  PaymentProviderOptionsDto,
} from ".";
import { updateCustomer } from "./CustomerUpdateThunk";
import { fetchCustomer } from "./FetchCustomerThunk";

export type CustomerStatus = Status;

export type CustomerPassword = {
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;
};

export type CustomerState = {
  data: CustomerDtoAddressDto<CustomerDto | NewCustomerDto> | null;
  error: string | null;
  status: CustomerStatus;
  isUpdated: boolean;
  paymentProviderOptions: PaymentProviderOptionsDto[];
};

const initialState: CustomerState = {
  data: null,
  error: null,
  status: "loading",
  isUpdated: false,
  paymentProviderOptions: [],
};

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    addAltAddress(state, { payload }) {
      state.data = payload;
    },
    resetIsUpdated(state) {
      state.isUpdated = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCustomer.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchCustomer.fulfilled, (state, { payload }) => {
        if ("type" in payload) {
          state.data = null;
          state.error = errorMapper(payload);
          state.status = "error";
          return state;
        }

        if (payload.data.altAddress !== undefined) {
          state.data = payload.data as CustomerDto;
        } else {
          state.data = {
            id: payload.data.id,
            name: payload.data.name,
            email: payload.data.email,
            address: {
              street: payload.data.address,
              postalCode: payload.data.postalCode,
              city: payload.data.city,
              country: payload.data.country,
            },
            phone: payload.data.phone,
            website: payload.data.website,
            balance: payload.data.balance,
            syncedAt: payload.data.syncedAt,
            status: payload.data.status,
            canImpersonate: payload.data.canImpersonate,
            isImpersonated: payload.data.isImpersonated
          } as NewCustomerDto;
        }

        state.error = null;
        state.status = "idle";
      })
      .addCase(fetchCustomer.rejected, (state, _) => {
        state.error = "Onze excuses er ging iets mis.";
        state.data = null;
        state.status = "error";
      })
      .addCase(updateCustomer.pending, state => {
        state.status = "loading";
      })
      .addCase(updateCustomer.fulfilled, (state, { payload }) => {
        if (payload && "type" in payload) {
          state.error = errorMapper(payload);
          state.status = "error";
          return state;
        }

        state.error = null;
        state.isUpdated = true;
      })
      .addCase(updateCustomer.rejected, (state, _) => {
        state.error = "Onze excuses er ging iets mis.";
        state.status = "error";
      })
      .addCase(getPaymentProviderOptionsThunk.pending, state => {
        state.status = "loading";
      })
      .addCase(
        getPaymentProviderOptionsThunk.fulfilled,
        (state, { payload }) => {
          state.paymentProviderOptions = payload.data;
          state.status = "success";
        }
      )
      .addCase(getPaymentProviderOptionsThunk.rejected, state => {
        state.status = "error";
        state.error = "Onze excuses er ging iets mis.";
      });
  },
});

export const { addAltAddress, resetIsUpdated } = customersSlice.actions;
