import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";

import { updateBalanceThunk } from "@/modules/basket/data/thunks/UpdateBalanceThunk";
import { updateSaturdayDeliveryThunk } from "@/modules/basket/data/thunks/UpdateSaturdayDeliveryThunk";
import { isHttpProblemDetail } from "@/modules/notifications/data/IsHttpProblemDetail";
import { getSaturdayDeliveryUsabilityThunk } from "@/modules/orders/api";
import { ProductDto } from "@/modules/products";

import { BasketProduct } from "../api";
import { BasketDto } from "../api/BasketDto";
import { BasketStatus } from "./BasketStatus";
import { loadBasketThunk } from "./thunks/LoadBasketThunk";
import { updateBasketProductsThunk } from "./thunks/UpdateBasketProductsThunk";
import { updateOrderItemsThunk } from "./thunks/UpdateOrderItemsThunk";
import { updateActiveDiscountThunk } from "./thunks/UpdateActiveDiscountThunk";

type BasketState = {
  basket: BasketDto;
  useBalance: boolean;
  saturdayDeliveryProduct?: ProductDto | null;
  status: BasketStatus;
  previousBasket: BasketDto;
};

const initialState: BasketState = {
  basket: {
    balanceDiscount: 0,
    couponDiscount: 0,
    activeDiscount: null,
    products: [],
    rawPrice: 0,
    totalPrice: 0,
    VATAmount: 0,
    canUseBalance: false,
  },
  useBalance: false,
  saturdayDeliveryProduct: null,
  status: "loading",
  previousBasket: null!,
};

export const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    addProduct(state, action: PayloadAction<BasketProduct>) {
      state.basket.products.push(action.payload);
    },
    updateProduct(
      state,
      action: PayloadAction<Pick<BasketProduct, "id" | "quantity">>
    ) {
      state.basket.products = state.basket.products.map(x =>
        x.id === action.payload.id
          ? { ...x, quantity: action.payload.quantity }
          : x
      );
    },
    deleteProduct(state, action: PayloadAction<string>) {
      state.basket.products = state.basket.products.filter(
        x => x.id !== action.payload
      );
    },
    updateBalance(state) {
      state.useBalance = !state.useBalance;
    },
  },
  extraReducers: function (builder) {
    builder
      .addCase(loadBasketThunk.pending, state => {
        state.status = "loading";
      })
      .addCase(loadBasketThunk.fulfilled, (state, action) => {
        state.basket = action.payload.data;
        state.previousBasket = state.basket;
        state.useBalance = !!state.basket.balanceDiscount;
        state.status = "ready";
      });

    builder.addCase(updateBasketProductsThunk.fulfilled, (state, action) => {
      if (isHttpProblemDetail(action.payload)) {
        state.basket = state.previousBasket;
        return;
      }

      state.previousBasket = state.basket;
    });

    builder.addCase(updateOrderItemsThunk.fulfilled, (state, action) => {
      if (isHttpProblemDetail(action.payload)) {
        state.basket = state.previousBasket;
        return;
      }

      state.previousBasket = state.basket;
    });

    builder.addCase(updateBalanceThunk.fulfilled, (state, action) => {
      if (isHttpProblemDetail(action.payload)) {
        return;
      }

      state.basket.balanceDiscount = action.payload.data.balanceDiscount;

      state.previousBasket = state.basket;
    });

    builder.addCase(updateActiveDiscountThunk.fulfilled, (state, action) => {
      if (isHttpProblemDetail(action.payload)) {
        state.basket = state.previousBasket;
        return;
      }

      state.basket.activeDiscount = action.payload.data.activeDiscount;
      state.basket.couponDiscount = action.payload.data.couponDiscount;

      state.previousBasket = state.basket;
    });

    builder.addCase(
      getSaturdayDeliveryUsabilityThunk.fulfilled,
      (state, action) => {
        if (isHttpProblemDetail(action.payload)) {
          state.basket = state.previousBasket;
          return;
        }

        state.saturdayDeliveryProduct = action.payload.data;
      }
    );

    builder.addMatcher(
      isAnyOf(
        updateBalanceThunk.fulfilled,
        updateActiveDiscountThunk.fulfilled,
        updateOrderItemsThunk.fulfilled,
        updateSaturdayDeliveryThunk.fulfilled,
        updateBasketProductsThunk.fulfilled
      ),
      (state, action) => {
        if (isHttpProblemDetail(action.payload)) {
          return;
        }

        const remoteBasket = action.payload.data;

        state.basket = remoteBasket;
        state.useBalance = !!remoteBasket.balanceDiscount;

        state.previousBasket = state.basket;
      }
    );

    builder.addMatcher(
      isAnyOf(updateProduct, addProduct, deleteProduct),
      state => {
        handleNoBalanceProducts(state);
        optimisticUpdatePrices(state);
      }
    );
  },
});

function handleNoBalanceProducts(state: BasketState) {
  if (state.basket.canUseBalance) return;

  state.basket.balanceDiscount = 0;
  state.useBalance = false;
}

function optimisticUpdatePrices(state: BasketState) {
  state.basket.rawPrice = state.basket.products.reduce(
    (acc, curr) => acc + curr.quantity * +curr.price,
    0
  );

  const newPrice =
    state.basket.rawPrice -
    state.basket.balanceDiscount -
    state.basket.couponDiscount;

  state.basket.totalPrice = newPrice > 0 ? newPrice : 0;
}

export const { addProduct, deleteProduct, updateProduct, updateBalance } =
  basketSlice.actions;
