import { Flex, Text } from "@chakra-ui/react";

export function WarningBanner() {
  return (
    <Flex
      maxW="full"
      w="full"
      position="fixed"
      top="0"
      background="red.500"
      p="2"
      justifyContent="center"
      zIndex="10"
    >
      <Flex maxW="1248px" alignItems="center">
        <Text color="white">
          Wegens een technische storing is het op dit moment niet mogelijk om
          een order te plaatsen. Excuses voor het ongemak.
        </Text>
      </Flex>
    </Flex>
  );
}
