import {
  Container,
  Divider,
  Flex,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FiCheckCircle } from "react-icons/fi";
import { TbCreditCard } from "react-icons/tb";
import { Navigate } from "react-router-dom";

import { LabeledPrice, useAppSelector } from "@/modules/common";
import { selectHistory } from "@/modules/history/data/selectors/selectHistory";
import { OrderProduct } from "@/modules/orders/components";

export function OrderCheckoutSuccessPage() {
  const history = useAppSelector(selectHistory);

  if (!history) {
    return <Navigate replace to="/" />;
  }

  const filteredProducts = history.basket.products.filter(
    prod =>
      !prod.saturdayDelivery &&
      prod.id !== "410d3ef3-a07e-4dff-b2e6-df04747b47e5"
  );

  const saturdayDelivery = history.basket.products.find(
    prod => prod.saturdayDelivery
  );

  const deliveryCosts = history.basket.products.find(
    prod => prod.id === "410d3ef3-a07e-4dff-b2e6-df04747b47e5"
  );

  return (
    <Container maxW="1248px" pb={8}>
      <VStack bg="white" mt={10} p={10}>
        <Flex p={5}>
          <VStack
            borderRadius="50%"
            p={3}
            bgColor="bsGray.100"
            w="10rem"
            h="10rem"
            alignItems="center"
            justifyContent="center"
          >
            <TbCreditCard size={100} color="#ffffff" />
          </VStack>
          <Flex
            position="relative"
            mb={-10}
            ml={-10}
            bgColor="bsAccent"
            h="4rem"
            w="4rem"
            justifyContent="center"
            alignItems="center"
            borderRadius="50%"
          >
            <FiCheckCircle size={50} color="#FFFFFF" />
          </Flex>
        </Flex>
        <Heading as="h2" color="bsAccent">
          Bedankt voor je bestelling
        </Heading>
        <Text>
          We hebben je bestelling goed ontvangen en gaan direct voor je aan de
          slag!
        </Text>

        <VStack w="100%" alignItems="flex-start">
          <Heading w="100%" as="h4" fontSize="xl" mt={8} pb={4}>
            Dit heb je besteld
          </Heading>
          <Flex flexDirection="column" w={{ base: "100%" }} gap={3}>
            {history &&
              filteredProducts.map(product => (
                <OrderProduct key={product.id} {...product} />
              ))}
          </Flex>
        </VStack>
        <Flex
          justifyContent="space-between"
          w="full"
          flexDir="column"
          gap={4}
          mt={4}
        >
          <Flex px={2} pt={2}>
            <LabeledPrice
              flexRow
              label="Subtotaal"
              price={history.basket.rawPrice}
            />
          </Flex>
          {history && history.basket.couponDiscount !== 0 && (
            <Flex px={2}>
              <LabeledPrice
                flexRow
                isDiscount
                label="Kortingscode"
                price={history.basket.couponDiscount}
              />
            </Flex>
          )}
          {history && history.basket.balanceDiscount !== 0 && (
            <Flex px={2}>
              <LabeledPrice
                flexRow
                isDiscount
                label="Gebruikt saldo"
                price={history.basket.balanceDiscount}
              />
            </Flex>
          )}
          {!!deliveryCosts && (
            <Flex px={2}>
              <LabeledPrice
                flexRow
                label="Verzend- en handlingskosten"
                price={Number(deliveryCosts.price)}
              />
            </Flex>
          )}
          {saturdayDelivery && (
            <Flex px={2}>
              <LabeledPrice
                flexRow
                label="Weekendlevering"
                price={Number(saturdayDelivery.price)}
              />
            </Flex>
          )}
          <Flex px={2}>
            <LabeledPrice
              flexRow
              label="btw"
              price={history.basket.VATAmount}
            />
          </Flex>
          <Divider my={1} />
          <Flex bg={"bsGray.100"} py={4} px={2}>
            <LabeledPrice
              boldLabel
              boldPrice
              flexRow
              size="md"
              label="Totaal"
              price={history.basket.totalPrice}
              data-cy="cartTotalPrice"
            />
          </Flex>
        </Flex>
      </VStack>
    </Container>
  );
}
