import {
  AspectRatio,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { Link as ReactLink } from "react-router-dom";

import { round, useMoney } from "@/modules/common";
import { ChangeProductAmount } from "@/modules/common/components/ChangeProductAmount";

import fallbackImage from "../../../assets/bd-placeholder.svg";
import { BasketProduct } from "../api";

type Props = BasketProduct & {
  updateBasket(product: BasketProduct, quantity: number): void;
};

export const BasketItem = (props: Props) => {
  const { updateBasket, ...product } = props;
  const { name, price, quantity, thumbnailUrl, series, slug, priceVAT } =
    product;

  const [value, setValue] = useState(quantity);

  const handleRemoveItem = () => {
    updateBasket(product, 0);
  };

  const totalItemPriceWithoutVat = round(value * price);
  const formattedTotalItemPriceWithoutVat = useMoney(totalItemPriceWithoutVat);

  const vatPercentage =
    product.priceVAT > 0 ? round(product.priceVAT / product.price) : 0;
  const totalItemPriceWithVat = round(totalItemPriceWithoutVat * vatPercentage);
  const formattedTotalItemPriceWithVat = useMoney(totalItemPriceWithVat);

  const detailLink = series
    ? `/producten/series/${series}/${slug}`
    : `/producten/${slug}`;

  return (
    <Flex
      w="100%"
      bg="bsWhite"
      border="1px solid"
      borderColor="bsGray.200"
      justifyContent="space-between"
      alignItems="center"
      borderRadius={5}
      flexDir="row"
      gap={0}
    >
      <Flex justifyContent="center" alignItems="center">
        <AspectRatio ratio={1} w="60px" mx={4}>
          <Link as={ReactLink} w="full" to={detailLink}>
            <Image
              src={thumbnailUrl}
              fallbackSrc={fallbackImage}
              objectFit="contain"
              alt={name}
              blendMode="multiply"
              textAlign="center"
              h="full"
            />
          </Link>
        </AspectRatio>
      </Flex>
      <VStack
        w="100%"
        justifyContent="center"
        alignItems="start"
        lineHeight={0.8}
        p={4}
        pl={0}
      >
        <HStack justifyContent="space-between" alignItems="start" w="full">
          <Link as={ReactLink} to={detailLink} lineHeight={1.4}>
            <Text textAlign="left" fontWeight="medium" fontSize="sm" mb={2}>
              {name}
            </Text>
          </Link>
          <VStack minW="fit-content" alignItems="end">
            <Text
              fontSize="sm"
              fontWeight="bold"
              letterSpacing={1}
              pt={1}
              minW="fit-content"
            >
              {formattedTotalItemPriceWithoutVat}
            </Text>
            <Text
              fontSize="sm"
              letterSpacing={1}
              pt={1}
              color="gray.400"
              minW="fit-content"
            >
              incl. btw {formattedTotalItemPriceWithVat}
            </Text>
          </VStack>
        </HStack>
        <HStack
          w="full"
          lineHeight={1.2}
          gap={2}
          alignItems="end"
          justifyContent="space-between"
        >
          <ChangeProductAmount
            {...product}
            updateBasket={item => updateBasket(product, item.amount)}
            onAmountChange={val => setValue(val)}
          />
          <Icon
            as={FaTrash}
            _hover={{ cursor: "pointer" }}
            onClick={handleRemoveItem}
          />
        </HStack>
      </VStack>
    </Flex>
  );
};
