import { Flex, Image, Link, HStack, Text } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

import bdWebsiteLogo from "../../../../assets/bd-website-logo.png";

export const Footer = () => {
  return (
    <Flex
      w="full"
      position="fixed"
      bottom="0"
      left="0"
      zIndex="1"
      bg="white"
      p={4}
      py={2}
      justifyContent="center"
      borderTop="1px solid"
      borderTopColor="bsGray.100"
    >
      <Flex
        w="full"
        maxW="1248px"
        justifyContent={{ base: "center", md: "space-between" }}
        alignItems="center"
      >
        <Link href={`${process.env.VITE_BD_WEBSITE_URL}`}>
          <Text fontSize="xs" color="bsBlack" letterSpacing={1}>
            &copy; 2024 Beautydistrict B.V.
          </Text>
        </Link>{" "}
        <Text
          fontSize="xs"
          color="bsBlack"
          letterSpacing={1}
          display={{ base: "none", md: "inline" }}
        >
          <CheckIcon mr="2" /> Vanaf 150 euro ex btw. gratis verzending
        </Text>
      </Flex>
    </Flex>
  );
};
