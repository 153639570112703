import {
  Accordion,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";

import { updateBasketProductsThunk } from "@/modules/basket/data/thunks/UpdateBasketProductsThunk";
import {
  EmptySearchMessage,
  useAppDispatch,
  useAppSelector,
} from "@/modules/common";
import { Spinner } from "@/modules/common/components/Spinner";

import {
  hydrateFilters,
  Product,
  ProductDto,
  selectProducts,
  useSearch,
  useTags,
} from "..";
import { Filters, ProductFilters, ProductFiltersDrawer, Searchbar } from ".";
import { useSearchParams } from "react-router-dom";
import { ActiveFilter } from "../api/ActiveFilter";
import { selectImpersonation } from "@/modules/auth/data/selectImpersonation";

type Props = {
  products: ProductDto[];
  hasMore: boolean;
  isIdle: boolean;
  isLoading: boolean;
  onLoadMore: () => void;
};

// eslint-disable-next-line react/display-name
export const ProductsList = forwardRef(
  (
    { products, hasMore, isIdle, onLoadMore, isLoading }: Props,
    containerRef
  ) => {
    const { isActive, onFilter, tags } = useTags();
    const { activeFilters, totalHits } = useAppSelector(selectProducts);
    const { onSearch } = useSearch();

    const [searchParams, setSearchParams] = useSearchParams();

    const requestRef = useRef<() => void>();
    const dispatch = useAppDispatch();

    const isImpersonating = useAppSelector(selectImpersonation);

    function updateBasket(product: ProductDto, quantity: number) {
      if (requestRef.current) {
        requestRef.current();
      }

      const req = dispatch(updateBasketProductsThunk({ ...product, quantity }));

      requestRef.current = () => req.abort();
    }
    const [activeFilterIndexes, setActiveFilterIndexes] = useState<number[]>(
      []
    );

    const formattedTags = useMemo<Record<string, string[]>>(() => {
      if (!tags) return {};

      return tags.reduce((acc: any, curr) => {
        const key = curr.name;

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(...curr.values);

        return acc;
      }, {});
    }, [tags]);

    useEffect(() => {
      const activeFilterNames = new Set(activeFilters.map(f => f.category));
      if (tags.length === 0) return;
      const newArr: number[] = [];
      for (const [idx, tag] of tags.entries()) {
        if (tag.isDefault) {
          newArr.push(idx);
          continue;
        }

        if (activeFilterNames.has(tag.name)) {
          newArr.push(idx);
        }
      }
      setActiveFilterIndexes(newArr);
    }, [tags, activeFilters]);

    useEffect(() => {
      const currentParams: { [key: string]: string[] } = {};

      activeFilters.forEach(activeFilter => {
        if (currentParams[activeFilter.category]) {
          currentParams[activeFilter.category].push(activeFilter.value);
        } else {
          currentParams[activeFilter.category] = [activeFilter.value];
        }
      });

      setSearchParams(currentParams);
    }, [activeFilters]);

    useEffect(() => {
      const paramArray: ActiveFilter[] = [];

      for (const [key, value] of searchParams.entries()) {
        if (key === "_gl") return;
        // FIX: We should execute below line unless we're sure that the key is an existing filter
        paramArray.push({ category: key, value });
      }
      dispatch(hydrateFilters(paramArray));
    }, [searchParams]);

    const filters = (
      <Accordion
        allowMultiple
        border="none"
        w="full"
        index={activeFilterIndexes}
      >
        {Object.entries(formattedTags).map(([name, value], idx) => (
          <Filters
            key={name}
            name={name}
            value={value}
            isActive={isActive}
            isOpen={activeFilterIndexes.some(
              filterIndex => idx === filterIndex
            )}
            onFilter={onFilter}
            onOpen={() => setActiveFilterIndexes(prev => [...prev, idx])}
            onClose={() =>
              setActiveFilterIndexes(prev =>
                prev.filter(index => index !== idx)
              )
            }
          />
        ))}
      </Accordion>
    );

    return (
      <Box ref={containerRef as any} w="full">
        <Flex flexDir={{ base: "column", lg: "row" }} gap={{ base: 0, lg: 4 }}>
          <Flex
            flexDir={{ base: "row-reverse", lg: "column" }}
            position="sticky"
            minW="280px"
            top={{
              base: isImpersonating ? "120px" : "72px",
              lg: isImpersonating ? "200px" : "150px",
            }}
            h={{
              base: "full",
              lg: isImpersonating
                ? "calc(100vh - 265px)"
                : "calc(100vh - 215px)",
            }}
            overflowY="auto"
            bg="bsWhite"
            zIndex={1}
            px={0}
            alignItems="start"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                marginTop: "10px",
                marginBottom: "10px",
                width: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#5e5753",
                borderRadius: "24px",
              },
            }}
          >
            <HStack w="full" gap={3} zIndex={2} py={4}>
              <ProductFiltersDrawer
                productsLength={products.length}
                filters={filters}
              />
              <Searchbar onSearch={onSearch} />
            </HStack>
            <ProductFilters filters={filters} />
          </Flex>
          <Flex flexDir="column" w="full">
            {products.length === 0 && isIdle && <EmptySearchMessage />}
            <SimpleGrid
              spacing={{ base: 2, md: 5 }}
              alignSelf="flex-start"
              w="full"
              mt={{ base: 0, lg: 4 }}
              columns={{
                base: 1,
                md: 2,
                lg: 2,
                xl: 3,
                "2xl": 3,
              }}
              data-cy="productlist"
            >
              {products.map(product => (
                <Product
                  data-testid="product"
                  key={product.id}
                  {...product}
                  updateBasket={item => updateBasket(product, item.amount)}
                />
              ))}
            </SimpleGrid>
            {products.length > 0 && (
              <Flex
                flexDir="row"
                justifyContent="center"
                alignItems="center"
                mt={16}
              >
                <Divider />
                <Text
                  as="span"
                  w="500px"
                  textAlign="center"
                  fontSize="sm"
                  color="bsBlack"
                >
                  {products.length} van {totalHits} gezien
                </Text>
                <Divider />
              </Flex>
            )}
            {hasMore && isIdle && products.length > 0 && (
              <Button
                data-cy="loadMoreButton"
                borderRadius={5}
                backgroundColor="bsBlack"
                color="white"
                fontSize="xs"
                p={6}
                w="250px"
                fontWeight={200}
                mx="auto"
                my={8}
                _hover={{
                  bg: "bsBlack",
                }}
                _active={{
                  bg: "bsBlack",
                }}
                onClick={onLoadMore}
              >
                Toon meer producten
              </Button>
            )}
            {isLoading && <Spinner />}
          </Flex>
        </Flex>
      </Box>
    );
  }
);
