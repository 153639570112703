import { useCallback } from "react";
import { useAppDispatch } from "@/modules/common";
import { useNotifications } from "@/modules/notifications/hooks/UseNotifications";
import { AuthApi } from "../api/AuthApi";
import { auth } from "../firebase";

export function useStartImpersonation() {
  const dispatch = useAppDispatch();
  const notifications = useNotifications();

  const startImpersonation = useCallback(
    async (email: string) => {
      console.warn(`Starting impersonation for ${email}`);
      const res = await AuthApi.startImpersonation(email);

      if (res.status === 404) {
        notifications.error(
          "Er ging iets mis",
          `Er is geen gebruiker gevonden met het e-mailadres ${email}`
        );
        return;
      }

      setTimeout(async () => {
        await auth.currentUser?.getIdToken(true);

        const tokenResult = await auth.currentUser?.getIdTokenResult();

        if (!tokenResult || !tokenResult?.claims.impersonating) {
          notifications.error(
            "Er ging iets mis",
            "Het is niet gelukt om in te loggen als deze gebruiker."
          );
          if (!tokenResult) {
            console.error("Failed to get tokenResult");
            return;
          }

          console.error("Failed to set user claims");
          return;
        }

        window.location.reload();
      }, 500);
    },
    [dispatch]
  );

  return startImpersonation;
}
