import { axios } from "@/modules/common/Axios";

export class DownloadApi {
  // TODO: Create return type
  public static async get(orderNumber: string): Promise<any> {
    return axios
      .get(`/api/v1/orders/download/invoice/${orderNumber}`)
      .then(({ data }) => data);
  }
}
