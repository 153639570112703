import { Container } from "@chakra-ui/react";

import { selectIsLoading } from "../auth/data/selectIsLoading";
import { useAppSelector } from "../common";
import { Spinner } from "../common/components/Spinner";
import { Banner, Footer, Heading } from ".";
import { selectUser } from "../auth/data/selectUser";
import { ImpersonationBanner } from "./components/Heading/components/ImpersonationBanner";
import { selectImpersonation } from "../auth/data/selectImpersonation";
import { WarningBanner } from "./components/Heading/components/WarningBanner";
import { useErrorModes } from "../auth/hooks/useErrorModes";

type Props = {
  children: JSX.Element;
};

export function Layout({ children }: Props) {
  const isLoading = useAppSelector(selectIsLoading);
  const user = useAppSelector(selectUser);
  const impersonateeEmail = useAppSelector(selectImpersonation);

  if (isLoading) {
    return <Spinner />;
  }

  const uiMode = useErrorModes(user?.email);

  return (
    <Container maxW="full" p={0} pb={8}>
      {impersonateeEmail && (
        <ImpersonationBanner impersonateeEmail={impersonateeEmail} />
      )}
      {uiMode.technicalDifficulties && <WarningBanner />}
      <Heading isImpersonating={impersonateeEmail} />
      {children}
      <Footer />
    </Container>
  );
}
