import { setImpersonation } from "@/modules/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { CustomersApi } from ".";

export const fetchCustomer = createAsyncThunk(
  "customers/me",
  async (params, thunkApi) => {
    const customer = await CustomersApi.get();
    // @ts-ignore
    if (customer?.data?.isImpersonated) {
      thunkApi.dispatch(
        // @ts-ignore
        setImpersonation({ impersonateeEmail: customer.data.email })
      );
    }
    return customer;
  }
);
