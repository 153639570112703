import {
  Flex,
  Heading as ChakraHeading,
  Image,
  Link,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link as ReactLink, Navigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";

import BdContact from "../../assets/bd-contact.jpg";
import { CTAButton, CustomInputField, useAppSelector } from "../common";
import { Banner, Heading } from "../layout";
import { selectIsAuthenticated } from "./data/selectIsAuthenticated";
import { auth } from "./firebase";

const schema = yup.object({
  email: yup
    .string()
    .email("Vul een geldig e-mailadres in")
    .required("Vul een e-mailadres in"),
  password: yup.string().required("Vul een wachtwoord in"),
});

export function LoginPage() {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const handleLogin = handleSubmit(async data => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password);
    } catch (e) {
      setError(!auth.currentUser);
    } finally {
      setLoading(false);
    }
  });

  if (isAuthenticated) {
    const destination = redirect || "/";
    return <Navigate replace to={destination} />;
  }

  return (
    <Flex
      h="full"
      w="full"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      maxW="1248px"
      mx="auto"
      pt="48px"
    >
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        spacingX={16}
        mx={{ base: 5, md: 20 }}
        pb={6}
      >
        <Flex w="half" h="full">
          <Image
            src={BdContact}
            alt="Beauty Shop Contact"
            h={{ base: "250px", md: "450px", lg: "750px" }}
            w="full"
            borderRadius={5}
            mb={5}
            objectFit="cover"
          />
        </Flex>
        <Flex flexDir="column" w="half" justifyContent="center">
          <ChakraHeading
            as="h1"
            size={{ sm: "lg", md: "2xl" }}
            fontWeight="bold"
            lineHeight={1.2}
            mb={{ base: 4, md: 8 }}
          >
            <Text lineHeight={1.2}>
              Inloggen <i>BEAUTYSHOP</i>
            </Text>
          </ChakraHeading>

          <Text fontSize="sm" mb={1}>
            Nog geen inloggegevens?
          </Text>
          <Text fontSize="sm">
            Klik dan{" "}
            <Link
              href={`${process.env.VITE_BD_CUSTOMER_REQUEST_URL}`}
              fontWeight="bold"
            >
              hier
            </Link>{" "}
            om klant te worden bij <b>Beautydistrict!</b>
          </Text>
          <form onSubmit={handleLogin}>
            <Flex
              justifyContent="space-between"
              w="full"
              flexDir="column"
              mt={5}
              gap={4}
            >
              <CustomInputField
                showLabel={false}
                label="E-mailadres"
                isInvalid={Boolean(errors.email) || error}
                errorMessage={errors.email?.message}
                register={register("email")}
              />
              <CustomInputField
                showLabel={false}
                label="Wachtwoord"
                isInvalid={Boolean(errors.password) || error}
                type="password"
                errorMessage={
                  error
                    ? "De combinatie van e-mail en wachtwoord zijn niet bij ons bekend"
                    : errors.password?.message
                }
                register={register("password")}
              />

              <CTAButton
                type="submit"
                width="full"
                isLoading={loading}
                buttonText="Inloggen"
              />

              <Link
                as={ReactLink}
                to={"/reset"}
                mt={5}
                data-cy="resetPasswordLink"
                alignSelf="center"
                fontSize="sm"
                _hover={{ textDecor: "underline" }}
              >
                Wachtwoord vergeten?
              </Link>
            </Flex>
          </form>
        </Flex>
      </SimpleGrid>
    </Flex>
  );
}
