import { PricelistsPage } from "./PricelistsPage";
import { ModuleRouter } from "@/modules/common";
import { ProtectiveRoute } from "@/modules/common/components/ProtectiveRoute";

export const pricelistsPageConfiguration: ModuleRouter = [
  {
    path: "/prijslijsten",
    element: (
      <ProtectiveRoute>
        <PricelistsPage />
      </ProtectiveRoute>
    ),
  },
];
